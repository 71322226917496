interface LoginResponse {
  token: string
}

// Do a login API call with the given username and password.
// Throws an error on failure.
export const loginUser = async (credentials: Record<string, string>) => {
  return await $api<LoginResponse>('/auth/sign_in', {
    method: 'POST',
    body: credentials,
  })
}

export const signoutUser = async () => {
  return await $api<any>('/auth/sign_out', {
    method: 'DELETE',
  })
}

export const requestPasswordReset = async (email: string) => {
  return await $api<any>('/auth/password', {
    method: 'POST',
    body: { email },
  })
}

// This calls the API to reset the password, by providing the new password and
// the uid, client and accessToken. An alternative method is by using a password
// reset token. See the resetPasswordWithToken function below.
export const resetPassword = async ({
  password,
  passwordConfirmation,
  resetPasswordToken,
  uid,
  client,
  accessToken,
}: {
  password: string
  passwordConfirmation: string
  // Method 1: user
  uid: string
  client: string
  accessToken: string
  // Method 2: password reset token
  resetPasswordToken: string
}) => {
  if (!(uid && client && accessToken) && !resetPasswordToken) {
    throw new Error(
      'You must provide either the uid, client and accessToken or the resetPasswordToken',
    )
  }

  return await $api<{ data: User, message: string }>('/auth/password', {
    method: 'PUT',
    body: {
      password,
      passwordConfirmation,
      resetPasswordToken,
    },
    headers: {
      'uid': uid,
      'client': client,
      'access-token': accessToken,
    },
  })
}
