// Note
// There's no official Nuxt 3 integration yet. Being worked on here: https://github.com/getsentry/sentry-javascript/discussions/6929
// For Nuxt 2 there's a package here: https://sentry.nuxtjs.org/getting-started/setup,
// What can be concluded from that is that client and server need a different setup.
//
// Other useful sources:
// - https://www.ractoon.com/articles/using-sentry-with-nuxt-3
// - https://www.reddit.com/r/Nuxt/comments/13xs0pt/nuxt_3_sentry_integration/
// - https://localazy.com/blog/nuxt-3-tailwind-i18n-eslint-starter#add-sentry
// - https://docs.sentry.io/platforms/javascript/guides/vue/

import * as Sentry from '@sentry/vue'

const logSentryErrors = false

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  // We're using the Sentry ENV to determine the environment
  const isProduction = config.public.sentry.env === 'production'
  const isStaging = config.public.sentry.env === 'staging'

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.env,

    // Only enable BrowserTracing and Replay on the client
    integrations: process.client ? [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    })] : [],

    // Performance Monitoring
    tracesSampleRate: isProduction || isStaging ? 0.1 : 1.0,
    tracePropagationTargets: [config.public.railsApiURL],

    // Session Replay
    // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
    replaysSessionSampleRate: isProduction || isStaging ? 0.1 : 1.0,
    // The sample rate for replays that are recorded when an error happens. This type of replay will record up to
    // a minute of events prior to the error and continue recording until the session ends.
    replaysOnErrorSampleRate: 0.1,

    beforeSend(event, hint) {
      // console.log(`beforeSend`, event, hint)
      // Check if it is an exception, and if so, log it.
      if (logSentryErrors && event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        )
      }
      // Continue sending to Sentry
      return event
    },
  })

  // const { vueApp } = nuxtApp
  // vueApp.mixin(
  //   Sentry.createTracingMixins({
  //     trackComponents: true,
  //     timeout: 2000,
  //     hooks: ['activate', 'mount', 'update'],
  //   }),
  // )
  // Sentry.attachErrorHandler(vueApp, {
  //   logErrors: false,
  //   attachProps: true,
  //   trackComponents: true,
  //   timeout: 2000,
  //   hooks: ['activate', 'mount', 'update'],
  // })
})
