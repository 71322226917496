import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_iigqpg2cn52w3d4chddkixnak4/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/.app/middleware/auth.global.ts";
import set_45tenant_45global from "/vercel/path0/stagedoos/middleware/set_tenant.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_iigqpg2cn52w3d4chddkixnak4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  set_45tenant_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}