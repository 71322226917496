export default function useTenant() {
  const tenant = useCookie<string>('tenant', {
    secure: true,
    watch: true,
  })

  function setInitialTenant() {
    const host = useRequestURL().host
    const publicConfig = useRuntimeConfig().public
    const tenantsConfig = publicConfig.tenants as Record<string, string>
    const defaultTenant = publicConfig.defaultTenant as string
    const initialTenant = tenantsConfig[host] || defaultTenant
    tenant.value = initialTenant
  }

  // if (!tenant.value) setInitialTenant() //disabled due to issue on mas4you launch, can be re-enabled later
  setInitialTenant()

  return { tenant }
}
