<script setup lang="ts">
const props = defineProps<{
  error: any | Ref<any>
}>()

const emit = defineEmits(['clearError'])

const error = ref(unref(props.error))

console.error(`Tairo error:`, error.value)

const isWrongCenterError
  = props.error.message && props.error.message.includes('wrong center')

const title = computed(() => {
  if (isWrongCenterError) {
    return 'Ingelogd op de verkeerde website'
  }

  if (error.value?.statusCode === 401) {
    return 'Not authorized'
  }

  if (error.value?.statusCode === 403) {
    return 'Verboden toegang'
  }

  if (error.value?.statusCode === 404) {
    return 'Page not found'
  }

  if (error.value?.statusCode) {
    return `Oops... Something went wrong (foutcode ${error.value?.statusCode})`
  }

  return `Oops... Something went wrong`
})

const description = computed(() => {
  if (isWrongCenterError) {
    return 'Neem contact op met de beheerder van deze website. Dit gebruikersaccount\
        is geregistreerd bij een van de andere partners in het\
        samenwerkingsverband.'
  }

  if (error.value?.statusCode === 401) {
    return 'You are not authorized to access this page.'
  }

  if (error.value?.statusCode === 403) {
    return 'Je hebt geen toegangsrechten voor deze pagina.'
  }

  if (error.value?.statusCode === 404) {
    return 'We couldn\'t find the page you were looking for, please contact a system administrator or try again later.'
  }

  return 'An error has occured. If the problem persists, please contact a system administrator or try again later.'
})

const app = useAppConfig()
const handleError = () => clearError({ redirect: '/' })
const retry = () => clearError()

// This is a quickfix to allow the parent to clear the error after navigating away from the error page
// According to the Nuxt docs, errors should be cleared automatically on navigation, but that's not the case.
// The parent can do this: <TairoError :error="error" @clearError="() => (error.value = null)" />
const router = useRouter()
router.afterEach(() => emit('clearError'))

// Show/hide demo stack trace
const showStackTrace = ref(true)
</script>

<template>
  <div class="pb-16">
    <BasePlaceholderPage
      :title="title"
      :subtitle="description"
      image-size="md"
      class="relative !items-end"
    >
      <template #image>
        <component
          :is="resolveComponentOrNative(app.tairo.error.logo.component)"
          v-if="app.tairo.error?.logo?.component"
          v-bind="app.tairo.error.logo.props"
        />
      </template>

      <div class="mt-4">
        <div
          class="text-muted-400/20 dark:text-muted-400/10 absolute inset-x-0 top-1/3 -translate-y-1/2 text-[13rem] font-bold sm:text-[20rem]"
        >
          <span>{{ props.error?.statusCode }}</span>
        </div>
        <div
          class="mx-auto flex w-full max-w-xs items-center justify-center gap-2"
        >
          <BaseButton
            shape="curved"
            class="mx-auto !h-12 w-full max-w-[160px] items-center gap-2"
            @click="handleError"
          >
            <Icon name="feather:arrow-left" />
            Back to home
          </BaseButton>
          
        </div>
        
      </div>
    </BasePlaceholderPage>

    
  </div>
</template>

<style>
.stack {
  @apply text-sm;
}
.stack.internal {
  @apply ps-4 text-xs opacity-100;
}
</style>
